@import '~normalize.css/normalize.css';
@import '~tailwindcss/base';
@import '~tailwindcss/components';
@import '~tailwindcss/utilities';
@import './base';
@import './media';
@import './fonts';

@import './variables';
@import './menu';
@import './dizzy';
@import './dollyzoom';
@import './vhs';
@import './cam';
@import './review';
@import './social';
@import './footer';

html {
  height: 100%;
}

body {
  font-size: 16px;
  font-family: ArialMT, Roboto, Helvetica, sans-serif;
  background-color: $primary-bg;
}

.nav {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  position: relative;
}
.nav__fixed-btn {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 175px;
  justify-content: space-between;
}
.nav__logo {
  a {
    display: flex;
    align-items: center;
    color: $color-dark;
    font-family: ArialMT, Roboto, sans-serif;
  }
}

.nav__logo img {
  max-height: 38px;
}

.nav__download-btn {
  z-index: 23;
  width: 120px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $color-dark;
  box-sizing: border-box;
  border-radius: 10px;
}
.nav__download-btn > a {
  color: $color-dark;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
}
.nav__download-btn--white {
  border-color: white;
  a {
    color: white;
  }
}

#btn--fixed {
  position: fixed;
  z-index: 4;
  left: 0;
  bottom: 2%;
  width: 100%;
}

#title {
  color: #f6f6f6;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 2em;
}

.download-btn {
  height: 55px;
  text-decoration: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  background: #f7f7f7;
}

.download-btn--full-width {
  width: 95%;
}

.download-btn__content {
  display: flex;
  color: #000;
}
.download-btn__text {
  padding-left: 5px;
  font-family: SFui, ArialMT, Roboto, Helvetica, sans-serif;
}
.download-btn__text p:first-child {
  line-height: 1;
  font-size: 0.7em;
}
.download-btn__text p:last-child {
  line-height: 1;
  font-size: 1.3em;
}

.btn-gradient {
  background: linear-gradient(85.3deg, #f29358 7.08%, #f63cc0 30.8%, #54c2fd 93.02%);
  box-sizing: border-box;
}

.hero {
  height: 80%;
  position: relative;
  overflow: hidden;
}

.hero__content {
  position: absolute;
  top: 70%;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.hero__content--title {
  font-family: ArialMT, Roboto, Helvetica, sans-serif;
  letter-spacing: 0.18em;
  line-height: 2;
  opacity: 0.9;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.hero__content--text {
  font-family: SFpro, Helvetica, sans-serif;
  color: #f2f2f2;
  opacity: 0.7;
  text-align: center;
}

.arrows {
  display: flex;
  width: 26px;
  margin-top: 2em;
  margin-left: calc(50% - 13px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

/*
==============================================
floating
==============================================
*/

.floating {
  animation-name: floating;
  -webkit-animation-name: floating;

  animation-duration: 1s;
  -webkit-animation-duration: 1s;

  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0%);
  }
  50% {
    -webkit-transform: translateY(10%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}

.bigEntrance {
  animation-name: bigEntrance;
  -webkit-animation-name: bigEntrance;

  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;

  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;

  visibility: visible !important;
}

@keyframes bigEntrance {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}

@-webkit-keyframes bigEntrance {
  0% {
    -webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    -webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    -webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}

#why_dizzi,
#camera_modes,
#review,
#social {
  color: white;
}

#hero {
  height: 100vh;
}

.section {
  position: relative;
}

.section__content {
  padding: 3.5em 0 1em;
  position: relative;
}

.section__title {
  line-height: 2;
  font-family: ArialMT, Roboto, Helvetica, sans-serif;
  color: $primary-bg;
  letter-spacing: 5px;
  text-shadow: -1px 1px 0 #de8b3e, 1px 1px 0 #de8b3e, 1px -1px 0 #de8b3e, -1px -1px 0 #de8b3e;
}

.ts-white {
  text-shadow: -1px 1px 0 #f7f7f7, 1px 1px 0 #f7f7f7, 1px -1px 0 #f7f7f7, -1px -1px 0 #f7f7f7;
}

.font-sf {
  font-family: SFui, ArialMT, Roboto, Helvetica, sans-serif;
}

.social__text {
  color: #bdbdbd;
}
.review__text {
  color: #3c3b3b;
}

@media screen and (max-width: 1024px) {
  .download-btn--absolute {
    position: absolute;
    bottom: 5%;
    margin: 0 2.5%;
  }
}

@media (orientation: landscape) and (max-width: 1024px) {
  .section {
    min-height: 600px;
  }
  .hero {
    height: 480px;
  }
  .section__content {
    width: 65%;
    margin: 0 auto;
  }
  .swiper-container {
    height: 450px;
  }
  .swiper-container-review {
    width: 95%;
  }
  .swiper-container-social {
    height: 100%;
  }
  #social {
    .slide__wrapper {
      height: 300px;
    }
  }
  .section__video {
    height: 450px;
  }
  .vhs__video,
  .dollyzoom__video {
    height: 450px;
  }
  .menu__btn {
    top: 2em;
  }
  .menu {
    transform: translateY(-10%);
    .download-btn {
      width: 40%;
    }
  }
  .menu__title {
    line-height: 1.2;
  }
  .menu__item {
    font-size: 1.2em;
    padding: 9px 0;
  }
}

@media screen and (min-width: 1024px) {
  .arrows {
    position: absolute;
    bottom: 7%;
    margin-top: 0;
  }
  .hero {
    height: 100vh;
  }
  .section__content {
    padding-bottom: 5em;
  }
  #review .section__content {
    padding-bottom: 1em;
  }
}

@media screen and (orientation: portrait) {
  .hero {
    height: 80%;
  }
}
