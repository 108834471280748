// A Hassle-Free Way to Self-Host Google Fonts - https://google-webfonts-helper.herokuapp.com/fonts

@font-face {
  font-display: fallback;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Roboto-Light.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: ArialMT;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/arlrdbd.ttf');
}

@font-face {
  font-display: fallback;
  font-family: SFui;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/SFUIText-Regular.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: SFpro;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/SFProDisplay-Regular.ttf');
}
