.cam__video {
  height: 90vh;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .cam__video {
    height: 80vh;
    width: 40%;
  }
}
