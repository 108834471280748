.dollyzoom__video {
  height: calc(100vh - 4.5em - 96px);
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0 auto;
}
.dollyzoom__video--inner {
  height: 60%;
  overflow: hidden;
  width: 55%;
  position: relative;
  top: 15%;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .dollyzoom__video {
    height: 70vh;
    width: 40%;
  }
}
