.footer {
  position: relative;
  border-top: 1px solid $text-secondary;
  line-height: 4;
  color: $text-secondary;
  font-family: ArialMT, Roboto, Helvetica, sans-serif;
}
.footer ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.footer ul > li {
  padding: 0 2em;
  a {
    font-family: SFui, ArialMT, Roboto, Helvetica, sans-serif;
    font-size: 1.2em;
  }
}
.footer ul > li:hover {
  transform: scale(1.3);
  a {
    color: $text-primary;
  }
}
