#menu-btn {
  z-index: 25;
  position: fixed;
  top: 20px;
  cursor: pointer;
  -webkit-transition: left 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  transition: left 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
}

#menu-btn div {
  width: 22px;
  height: 2px;
  border-radius: 2px;
  margin-bottom: 8px;
  //background-color: $color-dark;
  background-color: white;
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91), opacity 500ms,
    box-shadow 250ms, background-color 500ms;
  transition: transform 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91), opacity 500ms, box-shadow 250ms,
    background-color 500ms;
}
#menu-btn.menu-btn--white {
  div {
    background-color: white;
  }
}

#menu-btn div:last-child {
  margin: 0;
}

#menu-btn.opened div {
  background-color: #fff;
}

#menu-btn.opened:hover > div {
  box-shadow: 0 0 1px #343838;
}

#menu-btn.opened #top {
  -webkit-transform: translateY(10px) rotate(-135deg);
  -ms-transform: translateY(10px) rotate(-135deg);
  transform: translateY(10px) rotate(-135deg);
}

#menu-btn.opened #middle {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

#menu-btn.opened #bottom {
  -webkit-transform: translateY(-10px) rotate(-45deg);
  -ms-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
}

.menu__sidebar {
  position: fixed;
  z-index: 24;
  overflow: auto;
  top: 0px;
  right: -100%;
  width: 100%;
  opacity: 0;
  padding: 20px 0px;
  height: 100%;
  background-color: $primary-bg;
  color: #fff9f3;
  -webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  box-shadow: -300px 20px 200px 100px rgba(0, 0, 0, 0.8);
  .nav__logo {
    position: fixed;
    top: 15px;
    padding-left: 14px;
  }
}

.menu__sidebar.opened {
  right: 0px;
  opacity: 1;
}

.menu {
  position: relative;
  -webkit-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
}

.menu__title {
  font-family: ArialMT, Roboto, sans-serif;
  font-size: 4em;
  line-height: 2em;
  text-align: center;
  letter-spacing: 0.11em;
  color: #3c3b3b;
}
.menu__btn {
  position: relative;
  top: 7em;
}

.menu__list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.menu__item {
  cursor: pointer;
  font-size: 1.5em;
  padding: 15px 0;
  -webkit-transition: all 250ms;
  transition: all 250ms;
  display: flex;
  justify-content: center;
}
.menu__item > a {
  text-decoration: none;
  font-family: ArialMT, Roboto, Helvetica, sans-serif;
  letter-spacing: 0.18em;
  color: #fff9f3;
  opacity: 0.9;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
.menu__item--active > a {
  border-bottom: 2px solid white;
}
.menu__item:hover > a {
  font-size: 1.3em;
}
