.swiper-container-review {
  width: 95vw;
  overflow: hidden;
  height: 60vh;
  .swiper-slide > div:first-child {
    height: 95%;
  }
}
.swiper-pagination-review {
  position: absolute;
  top: 60% !important;
  .swiper-pagination-bullet-active {
    height: 0.3em !important;
    width: 0.7em !important;
    background-color: $text-primary !important;
    border-radius: 10px !important;
  }
}

.review {
  background: #1c1c1e;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.65);
  border-radius: 8px;
  overflow: hidden;
}
.review__contact {
  color: #84838c;
  font-family: SFui, Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

.review__title span {
  p {
    font-weight: 600;
  }
  height: 25px;
  width: 25px;
  display: inline-block;
  background: no-repeat center/100% url('../images/ic_Star@2x.png');
}
.review p {
  font-family: SFui, Arial, Helvetica, sans-serif;
  line-height: 1.6;
}
.review__content {
  font-family: SFui, Arial, Helvetica, sans-serif;
  color: $text-primary;
  line-height: 1.5em;
}
@media screen and(max-width: 1024px) {
  .review__title span {
    height: 16px;
    width: 16px;
  }
}
@media screen and(min-width: 1024px) {
  .swiper-container-review {
    width: 100%;
    .swiper-slide > div:first-child {
      height: 90%;
    }
  }
  .swiper-pagination-review {
    right: 50px !important;
  }
}
