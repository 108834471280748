.vhs__video {
  height: calc(100vh - 1.5em - 96px);
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .vhs__video {
    height: 70vh;
    width: 40%;
  }
}
