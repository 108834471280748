.swiper-container-social {
  width: 100vw;
  overflow: hidden;
  height: 50vh;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.social__title {
  color: $text-primary;
  letter-spacing: 0.1em;
}
.social__subtitle {
  font-family: SFui, Arial, Helvetica, sans-serif;
  color: $text-gray;
}
