#why_dizzi p {
  font-family: SFpro, Roboto, Helvetica, sans-serif;
}
.swiper-container {
  width: 100%;
  height: 70vh;
  z-index: 0 !important;
}
.slide__wrapper {
  position: relative;
  height: 100%;
}
.swiper-pagination-bullet {
  background-color: #c3c3c3 !important;
}
.swiper-pagination-bullet-active {
  height: 0.7em !important;
  width: 0.3em !important;
  background-color: $text-primary !important;
  border-radius: 10px !important;
}
.photo {
  div:first-child {
    position: relative;
    top: 53%;
    left: 15%;
    width: 74%;
    height: 35%;
    overflow: hidden;
  }
  div:nth-child(2) {
    width: 55%;
    height: 80%;
    position: relative;
    top: -35%;
    overflow: hidden;
  }
  div:nth-child(3) {
    font-family: ArialMT, Roboto, Helvetica, sans-serif;
    position: absolute;
    top: 13%;
    left: 60%;
    line-height: 1.3;
    font-size: 1.3em;
  }
}
.slide__title {
  p:first-child {
    font-size: 20px;
    line-height: 21px;
  }
  p:last-child {
    font-size: 34px;
    line-height: 38px;
  }
}
.slide__video {
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translateX(-50%) translateY(-50%);
}
.mode {
  div:first-child {
    height: 90%;
    top: 5%;
    position: relative;
    overflow: hidden;
  }
  div:nth-child(2) {
    font-family: ArialMT, Roboto, Helvetica, sans-serif;
    position: absolute;
    top: 15%;
    left: 58%;
    line-height: 1.3;
    font-size: 1.3em;
  }
}

.effects {
  & > div:first-child {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  & > div:nth-child(2) {
    position: absolute;
    top: 45%;
    left: 60%;
  }
  .rounded-img {
    margin: 0 5px;
    img {
      max-height: 58px;
      max-width: 58px;
      border-radius: 50%;
      border: 3px solid white;
    }
    &:nth-child(4) img {
      max-width: 69px;
      max-height: 69px;
      border: 3px solid lightskyblue;
    }
  }
  .effects__types {
    position: relative;
    top: -2%;
    overflow: hidden;
    box-shadow: inset 10px 0px 6px 0px rgba(0, 0, 0, 0.65);
  }
}
.style {
  div:first-child {
    width: 55%;
    height: 50%;
    left: 30%;
    top: 40%;
    position: absolute;
    overflow: hidden;
  }
  div:nth-child(2) {
    width: 45%;
    height: 70%;
    top: 5%;
    position: absolute;
    overflow: hidden;
  }
  div:nth-child(3) {
    position: absolute;
    top: 16%;
    left: 51%;
  }
}
.gallery {
  div:first-child {
    width: 100%;
    height: 85%;
    top: 2%;
    position: absolute;
    overflow: hidden;
  }
  div:last-child {
    position: absolute;
    top: 12%;
    left: 57%;
  }
}
.voiceovers {
  div:first-child {
    width: 100%;
    height: 85%;
    top: 2%;
    position: absolute;
    overflow: hidden;
  }
  div:last-child {
    position: absolute;
    top: 20%;
    left: 30%;
  }
}

@media screen and (min-width: 1024px) {
  .swiper-container {
    width: 55%;
  }
  .slide__title {
    p:first-child {
      font-size: 24px;
    }
    p:last-child {
      font-size: 38px;
      line-height: 45px;
    }
  }
  .photo {
    div:first-child {
      max-width: 55%;
      top: 50%;
      left: 40%;
    }
    div:nth-child(2) {
      width: 45%;
      height: 80%;
      top: -36%;
      left: 4%;
    }
    div:nth-child(3) {
      left: 53%;
    }
  }
  .mode {
    div:first-child {
      width: 80%;
      margin: 0 auto;
      top: 0;
    }
  }
  .effects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    div:first-child {
      width: 60%;
      height: 100%;
    }
  }
  .style {
    div:first-child {
      left: 35%;
    }
  }
}
